

export const options=[]

var permission = JSON.parse(localStorage.getItem('permission'))
for(let agent in permission){
    var child = []
    for(let store in permission[agent]){
        child.push({
            value:store,
            label:store.toUpperCase(),
            isLeaf:true
        })
        if (store=='jellycat'){
            for (let plat of permission[agent][store]){
                if (plat.indexOf('domestic')!=-1){
                    child.push({
                        value:'jellycat_local',
                        label:'JELLYCAT LOCAL',
                        isLeaf:true
                    })
                    break
                }
            }
        }else if (store=='aldi'){
            for (let plat of permission[agent][store]){
                if (typeof plat == 'object'){
                    child.push({
                        value:'aldi_eleme',
                        label:'ALDI ELEME',
                        isLeaf:true
                    })
                    child.push({
                        value:'aldi_jdo2o',
                        label:'ALDI JDDJ',
                        isLeaf:true
                    })
                    break
                }
            }
        }
    }
    options.push({
        value:agent,
        label:agent.toUpperCase(),
        children:child
    })
}


export function changeStore(values:any){
    var platSelectOptions = [];
    var suuplierOptions = []
    var permission = JSON.parse(localStorage.getItem('permission'))
    if (values[1].indexOf('jellycat')!=-1){
        for (let plat of permission[values[0]]['jellycat']){
            if (values[1]=='jellycat'){
                if (plat.indexOf('domestic')!=-1){
                   continue
                }else{
                    platSelectOptions.push({label:plat.toUpperCase(),value:plat})
                }
            }else if(values[1]=='jellycat_local'){
                if (plat.indexOf('domestic')!=-1){
                   platSelectOptions.push({label:plat.toUpperCase(),value:plat})
                }else{
                    continue
                }
            }
            
        }
    }else if (values[1].indexOf('aldi')!=-1){
                
        if(values[1]=='aldi_eleme'){
             platSelectOptions.push({label:'ele'.toUpperCase(),value:'ele'})
            
           
        }else if(values[1]=='aldi_jddj'){
            platSelectOptions.push({label:'jddj'.toUpperCase(),value:'jddj'})
   
        }else{
            for (let plat of permission[values[0]]['aldi']){
                if (typeof plat == 'object'){
                    if(values[1]=='aldi_eleme'){
                         platSelectOptions.push({label:'ele'.toUpperCase(),value:'ele'})
                         break
                       
                    }else if(values[1]=='aldi_jddj'){
                        platSelectOptions.push({label:'jddj'.toUpperCase(),value:'jddj'})
                        break
                    }else{
                        for(let s in plat){
                            if(['ele','jddj'].includes(s)){
                                continue
                            }
                            platSelectOptions.push({label:s.toUpperCase(),value:s})
                            if(typeof plat[s] =='object'){
                                for(let n of plat[s]['supplier']){
                                    suuplierOptions.push({label:n.toUpperCase(),value:n})
                                   
                                }
                            }
                        }
                    }
                }
                else{
                    platSelectOptions.push({label:plat.toUpperCase(),value:plat})
                }
           
            }
        }
    }else{
        for (let plat of permission[values[0]][values[1]]){
  
            if(typeof plat=='object'){
                for(let s in plat){
                    platSelectOptions.push({label:s.toUpperCase(),value:s})
                    if(typeof plat[s] =='object'){

                        for(let n of plat[s]['supplier']){
                            suuplierOptions.push({label:n.toUpperCase(),value:n})
                           
                        }
                    }
                }
            }else{
                platSelectOptions.push({label:plat.toUpperCase(),value:plat})
             
            }
            
            
        }
    }
         
   
    
    return [platSelectOptions,suuplierOptions]
}